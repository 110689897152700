import { ICreateInvoice } from '@/entity/invoice/create-invoice';
import { IInvoice } from '@/entity/invoice/invoice';
import { IInvoiceProject } from '@/entity/invoice/invoice-project';
import { IInvoiceListLine } from '@/entity/invoice/invoice-list';
import { IModelInvoice } from '@/entity/pdf/pdf-model-invoice';
import { ISignature } from '@/entity/pdf/pdf-signature';
import ApiBase from '@api/api-base';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';

class InvoiceApi extends ApiBase<IInvoice> {
    /**
     *
     */
    constructor(urlBase: string = 'api/invoices') {
        super(urlBase);
    }

    public async createInvoice(data: ICreateInvoice): Promise<ICancellableResult<string>> {
        return await ajaxCall.post<string, ICreateInvoice>(this.url, data);
    }

    public async delete(invoiceId: string): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean>('delete', this.url, { urlParameter: [invoiceId] });
    }

    public async setIsGeneratedToTrue(invoiceId: string, body: any): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, any>('post', this.url, {
            payload: {
                ToAttentionOf: body.toAttentionOf,
                // SigningId: body.signingId,
                ModelInvoiceId: body.modelInvoiceId,
                ProgressComment: body.progressComment
            },
            urlParameter: ['setisgenerated', invoiceId]
        });
    }

    public async getAll(startDate: string, endDate: string): Promise<ICancellableResult<IInvoiceProject[] | null>> {
        let query:any = { startDate: startDate, endDate: endDate}
        if(!startDate) {
            query = {}
        }
        return await ajaxCall.baseAjaxCall<IInvoiceProject[]>('get', this.url, { urlParameter: ['allList'], queryParameter: { startDate: startDate, endDate: endDate} });
    }

    public async getAllByProjectId(projectId: string): Promise<ICancellableResult<IInvoice[] | null>> {
        return await ajaxCall.baseAjaxCall<IInvoice[]>('get', this.url, { urlParameter: ['byproject', projectId] });
    }

    public async getAllModelInvoice(): Promise<ICancellableResult<IModelInvoice[] | null>> {
        return await ajaxCall.baseAjaxCall<IModelInvoice[]>('get', this.url, { urlParameter: ['allModelsInvoice'] });
    }

    public async getAllSigningInvoice(): Promise<ICancellableResult<ISignature[] | null>> {
        return await ajaxCall.baseAjaxCall<ISignature[]>('get', this.url, { urlParameter: ['allSignings'] });
    }

    public async getGlobalInvoiceList(
        startDate: Date,
        endDate: Date | null
    ): Promise<ICancellableResult<IInvoiceListLine[]>> {
        if (endDate == null) {
            return await ajaxCall.baseAjaxCall<IInvoiceListLine[]>('get', this.url, {
                urlParameter: ['list', 'global'],
                queryParameter: {
                    startDate: startDate.toISOString()
                }
            });
        } else {
            return await ajaxCall.baseAjaxCall<IInvoiceListLine[]>('get', this.url, {
                urlParameter: ['list', 'global'],
                queryParameter: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString()
                }
            });
        }
    }

    public async exportInvoiceData(
        data: number[],
        sortKeySelected: string,
        isSortDesc: boolean
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                InvoiceIdList: data,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc
            },
            urlParameter: ['exportInvoiceData'],
            responseType: 'blob'
        });
    }

    public async exportInvoiceBaseListData(data: number[], dataAddsColumns: string[], sortKeySelected: string, isSortDesc: boolean, startDate: Date,
        endDate: Date | null ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                InvoiceIdList: data,
                AdditionalColumnsList: dataAddsColumns,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
                startDate : startDate,
                endDate : endDate
            },
            urlParameter: ['exportInvoiceBaseListdata'],
            responseType: 'blob'
        });
    }

    public async exportInvoicesByDate(data: number[], startDate: string | null, endDate: string | null, dataAddsColumns: string[], sortKeySelected: string, isSortDesc: boolean): Promise < ICancellableResult < string >> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                InvoiceIdList: data,
                StartDate: startDate ?? null,
                EndDate: endDate ?? null,
                AdditionalColumnsList: dataAddsColumns,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc
            },
            urlParameter: ['exportInvoicesDataByDate'],
            responseType: 'blob'
        });
    }
}

export const invoiceApi: InvoiceApi = new InvoiceApi();
